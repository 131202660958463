<template>
  <div>
    <paginated-table
      id="profile-loanables-table"
      endpoint="loanables"
      :columns="columns"
      :fetch-params="{
        for: 'profile',
      }"
      :show-action-column="false"
      :extra-filters="extraFilters"
      @row-clicked="(item) => $router.push(`/profile/loanables/${item.id}`)"
    >
      <template #head-buttons>
        <icon-button role="add" to="/profile/loanables/new"> Ajouter un véhicule </icon-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";

import Authenticated from "@/mixins/Authenticated";

import locales from "@/locales";
import { userIsApproved } from "@/helpers/permissions/users";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";

export default {
  name: "ProfileLoanables",
  components: { IconButton, PaginatedTable },
  mixins: [Authenticated],
  i18n: {
    messages: {
      en: {
        ...locales.en.loanables,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loanables,
        ...locales.fr.forms,
      },
    },
  },
  data() {
    return {
      hasLoadedSecondTab: false,
      extraFilters: [
        new Filter("accessibleInCommunity", "Communauté", "relation", {
          relation: "communities",
          label: "name",
          field: "id",
          params: { for: "profile" },
        }),
      ],

      columns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        new Column("name", "Nom", "text"),
        Column.withSelect("type", "Type", [
          { value: null, label: "Tous" },
          { value: "car", label: "Auto" },
          { value: "trailer", label: "Remorque" },
          { value: "bike", label: "Vélo" },
        ]),
        new Column("owner.user.full_name", "Propriétaire", "text", {}),
        new Column("current_loan.id", "Emprunt en cours", "text", {
          urlFct: (loanable) => `/loans/${loanable.current_loan?.id}`,
          sortable: false,
        }),
        new Column("current_loan.borrower_user.full_name", "Emprunteur\u2011se", "text", {
          sortable: false,
        }),
        Column.withSelect("availability_status", "Statut d'affichage", [
          { value: null, label: "Tous" },
          {
            value: "no_approved_communities",
            label: "Propriétaire sans communauté",
            variant: "warning",
          },
          { value: "wrong_type", label: "Type non partagé", variant: "danger" },
          { value: "no_availabilities", label: "Aucune disponibilité", variant: "warning" },
          { value: "unpublished", label: "Non publié", variant: "secondary" },
          { value: "has_availabilities", label: "Affiché", variant: "success" },
        ]),
      ],
    };
  },
  computed: {
    userIsApproved() {
      return userIsApproved(this.$store.state.user);
    },
  },
  methods: {
    onTabChange(tab) {
      if (tab === 1) {
        this.hasLoadedSecondTab = true;
      }
    },
  },
};
</script>

<style lang="scss">
.profile-loanables__loanables__loanable {
  margin-bottom: 20px;
}
</style>
