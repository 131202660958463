<script>
import DropdownSelect from "@/components/DropdownSelect.vue";
import FormsDateTimePicker from "@/components/Forms/DateTimePicker.vue";
import RelationInput from "@/components/Forms/RelationInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import UserComment from "@/components/shared/UserComment.vue";
import UserBlurb from "@/components/User/UserBlurb.vue";
import { onVisible } from "@/helpers/onVisible";
import { isAdminOfCommunity } from "@/helpers/permissions/communities";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { del, post, put } from "@/requests/server";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "IncidentForm",
  components: {
    DropdownSelect,
    FormsDateTimePicker,
    UserBlurb,
    RelationInput,
    IconButton,
    UserComment,
  },
  props: {
    incident: {
      type: Object,
      required: true,
    },
    loanable: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      noteText: null,
      addingNote: false,
      changingAssignee: false,
      changingBlockingUntil: false,
      blockingUntil: this.incident.blocking_until,
      showIncidentDetailsToBlockedBorrowers: this.incident.show_details_to_blocked_borrowers,
      notificationOptions: [
        {
          value: "all",
          label: "toutes",
        },
        {
          value: "resolved_only",
          label: "que la résolution",
        },
        {
          value: "none",
          label: "aucune",
        },
      ],
    };
  },
  computed: {
    canUpdateIncident() {
      const user = this.$store.state.user;

      return (
        this.incident.assignee?.id === user.id ||
        isCoownerOrOwner(user, this.loanable) ||
        isAdminOfCommunity(user, { id: this.incident.loan_community_id }) ||
        isGlobalAdmin(user)
      );
    },
    canAddNote() {
      return (
        this.canUpdateIncident || this.incident.reported_by_user?.id === this.$store.state.user.id
      );
    },
    isAdmin() {
      return isGlobalAdmin(this.$store.state.user);
    },
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    if (this.$refs.notes) {
      onVisible(this.$refs.notes, this.scrollToLastComment);
    }
  },
  methods: {
    update(incident) {
      incident.updated_at = dayjs().toISOString();
      this.blockingUntil = incident.blocking_until;
      this.showIncidentDetailsToBlockedBorrowers = incident.show_details_to_blocked_borrowers;
      this.$emit("update", incident);
    },
    scrollToLastComment() {
      this.$nextTick(() => {
        const noteDiv = this.$refs.notes;
        noteDiv.scrollTop = noteDiv.scrollHeight;
      });
    },
    async setAssignee(assignee) {
      await put(
        `/incidents/${this.incident.id}/assignee`,
        {
          assignee_id: assignee ? assignee.id : null,
        },
        {
          notifications: { action: "attribution de l'incident", onSuccess: "Responsable modifié!" },
        }
      );
      this.update({
        ...this.incident,
        assignee_id: assignee ? assignee.id : null,
        assignee,
      });
      this.changingAssignee = false;
    },
    async setBlockingUntil() {
      const { data } = await put(
        `/incidents/${this.incident.id}/block`,
        {
          blocking_until: this.blockingUntil,
          show_details_to_blocked_borrowers: this.showIncidentDetailsToBlockedBorrowers,
        },
        {
          notifications: { action: "modification à l'incident", onSuccess: "Incident modifié!" },
        }
      );
      this.update({
        ...this.incident,
        ...data,
      });
      this.changingBlockingUntil = false;
    },
    async resolve() {
      const { data } = await put(`/incidents/${this.incident.id}/complete`, this.action, {
        notifications: { action: "résolution d'incident", onSuccess: "incident résolu!" },
      });
      this.update({
        ...this.incident,
        ...data,
        resolved_by_user: this.$store.state.user,
      });
    },
    async reopen() {
      const { data } = await put(
        `/incidents/${this.incident.id}/reopen`,
        {},
        {
          notifications: { action: "réouverture d'incident", onSuccess: "incident réouvert" },
        }
      );
      this.update({
        ...this.incident,
        ...data,
      });
    },
    async addNote() {
      this.addingNote = true;
      const { data } = await post(
        `/incidents/${this.incident.id}/note`,
        {
          text: this.noteText,
        },
        {
          notifications: { action: "Ajoute de note" },
          cleanupCallback: () => (this.addingNote = false),
        }
      );
      this.update({
        ...this.incident,
        notes: [
          ...this.incident.notes,
          {
            ...data,
            author: this.$store.state.user,
          },
        ],
      });
      this.noteText = null;
      this.scrollToLastComment();
    },
    async updateDescription(text) {
      const { data } = await put(
        `/incidents/${this.incident.id}`,
        {
          comments_on_incident: text,
        },
        {
          notifications: { action: "modification de la description" },
        }
      );

      this.update({
        ...this.incident,
        ...data,
      });
    },
    async updateNote(note, text) {
      const { data } = await put(
        `/incidents/${this.incident.id}/note/${note.id}`,
        {
          text,
        },
        {
          notifications: { action: "modification de la mise à jour" },
        }
      );

      const notes = this.incident.notes.map((n) => {
        if (n.id === note.id) {
          return data;
        }
        return n;
      });

      this.update({
        ...this.incident,
        notes,
      });
    },
    async deleteNote(note) {
      const confirm = await this.$bvModal.msgBoxConfirm(
        "Êtes-vous sûr-e de vouloir supprimer cette mise à jour?",
        {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Oui, supprimer",
          cancelTitle: "Annuler",
          okVariant: "danger",
          cancelVariant: "ghost-secondary",
          footerClass: "p-2 border-top-0",
          centered: true,
        }
      );

      if (!confirm) {
        return;
      }

      await del(`/incidents/${this.incident.id}/note/${note.id}`, {
        notifications: { action: "suppression de la mise à jour" },
      });

      const notes = this.incident.notes.filter((n) => n.id !== note.id);
      this.update({
        ...this.incident,
        notes,
      });
    },
    async updateNotificationSubscription(level) {
      await put(
        `/incidents/${this.incident.id}/subscription`,
        { level },
        {
          notifications: {
            action: "modification des notifications",
          },
        }
      );
      this.incident.notification_subscription = level;
    },
    dayIsInThePast(day) {
      return dayjs().startOf("day").isAfter(day, "day");
    },
    dateIsInThePast(date) {
      return dayjs().isAfter(date, "minute");
    },
  },
});
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12" class="col-800-6">
        <dl>
          <dt>Description</dt>
          <dd>
            <user-comment
              :text="incident.comments_on_incident"
              :user="incident.reported_by_user"
              :date="incident.created_at"
              :update="
                isAdmin || incident.reported_by_user.id === user.id ? updateDescription : null
              "
            ></user-comment>
          </dd>
        </dl>
      </b-col>

      <b-col cols="12" class="col-800-6">
        <dl>
          <template v-if="incident.status === 'in_process'">
            <template v-if="incident.blocking_until || changingBlockingUntil">
              <dt>Bloque les emprunts jusqu'au</dt>
              <dd v-if="changingBlockingUntil && canUpdateIncident">
                <forms-date-time-picker
                  v-model="blockingUntil"
                  clearable
                  :disabled-dates-fct="dayIsInThePast"
                  :disabled-times-fct="dateIsInThePast"
                />
                <b-checkbox
                  v-if="blockingUntil"
                  v-model="showIncidentDetailsToBlockedBorrowers"
                  class="mt-2 mb-2"
                  switch
                >
                  Description et mises à jour visibles aux emprunteurs-ses affecté-es
                </b-checkbox>
                <b-alert
                  v-if="showIncidentDetailsToBlockedBorrowers"
                  variant="warning"
                  show
                  class="p-2"
                >
                  <strong>Attention&nbsp;:</strong> assurez-vous qu'aucune information sensible
                  (adresse, numéro de permis, etc.) n'est présente dans la description de l'incident
                  ou dans les mises à jour.
                </b-alert>
                <div class="mt-1">
                  <icon-button size="sm" role="save" :onclick="setBlockingUntil"
                    >Enregistrer</icon-button
                  >
                  <icon-button
                    class="ml-1"
                    size="sm"
                    role="cancel"
                    :onclick="
                      () => {
                        changingBlockingUntil = false;
                        blockingUntil = incident.blocking_until;
                        showIncidentDetailsToBlockedBorrowers =
                          incident.show_details_to_blocked_borrowers;
                      }
                    "
                    >Annuler</icon-button
                  >
                </div>
              </dd>
              <dd v-else-if="incident.blocking_until">
                <div class="d-flex flex-row align-items-start justify-content-between">
                  <div>
                    <div>
                      {{ incident.blocking_until | date }}
                      {{ incident.blocking_until | time }}
                    </div>

                    <b-badge
                      v-if="incident.show_details_to_blocked_borrowers && canUpdateIncident"
                      variant="warning"
                    >
                      Détails visibles aux emprunteurs-ses affecté-es.
                    </b-badge>
                  </div>

                  <icon-button
                    v-if="canUpdateIncident"
                    class="ml-2"
                    size="sm"
                    role="edit"
                    @click="changingBlockingUntil = true"
                  />
                </div>
              </dd>
            </template>

            <icon-button
              v-else-if="canUpdateIncident"
              size="sm"
              variant="outline-warning"
              icon="exclamation-triangle"
              class="mb-2"
              @click="changingBlockingUntil = true"
            >
              Bloquer les emprunts...
            </icon-button>
          </template>
          <template v-if="incident.resolved_by_user">
            <dt>Résolu par</dt>
            <dd>
              <user-blurb :user="incident.resolved_by_user" :show-admin-link="isAdmin" />
            </dd>
          </template>
          <dt>Responsable</dt>

          <dd v-if="!canUpdateIncident || !changingAssignee">
            <user-blurb
              v-if="incident.assignee"
              :user="incident.assignee"
              :show-admin-link="isAdmin"
            >
              <template #buttons>
                <div
                  v-if="canUpdateIncident && incident.status === 'in_process'"
                  class="d-flex align-items-center"
                >
                  <icon-button size="sm" role="edit" @click="changingAssignee = true">
                  </icon-button>
                  <icon-button class="ml-1" size="sm" role="remove-item" :onclick="setAssignee">
                  </icon-button>
                </div>
              </template>
            </user-blurb>
            <icon-button
              v-else-if="canUpdateIncident"
              role="add"
              size="sm"
              @click="changingAssignee = true"
            >
              Assigner
            </icon-button>
            <div v-else class="font-italic">Aucune personne assignée pour l'instant</div>
          </dd>
          <dd v-else>
            <relation-input
              :query="{
                value: 'id',
                text: 'full_name',
                slug: 'users',
                params: {
                  fields: 'avatar,name,last_name',
                  assignableToIncident: incident.id,
                  order: '-role',
                },
              }"
              :min-search-length="1"
              :value="incident.assignee_id"
              :object-value="incident.assignee"
              name="assignee_id"
              @input="setAssignee"
            />

            <icon-button
              v-if="canUpdateIncident"
              class="mt-1"
              size="sm"
              role="cancel"
              @click="changingAssignee = false"
              >Annuler</icon-button
            >
          </dd>
        </dl>
      </b-col>
    </b-row>
    <dl class="mb-0">
      <dt>Mises à jour</dt>
      <dd class="mb-0">
        <template v-if="incident.notes && incident.notes.length > 0">
          <div ref="notes" class="incident-notes">
            <user-comment
              v-for="note in incident.notes"
              :key="note.id"
              :text="note.text"
              :user="note.author"
              :date="note.created_at"
              :update="
                isAdmin || note.author.id === user.id ? (text) => updateNote(note, text) : null
              "
              :destroy="isAdmin || note.author.id === user.id ? () => deleteNote(note) : null"
              :updated-date="note.updated_at"
            />
          </div>
        </template>
        <div v-else class="font-italic">Aucune mise à jour pour l'instant</div>
        <b-form-group v-if="canAddNote">
          <div v-if="canAddNote" class="input-group">
            <b-form-textarea
              v-model="noteText"
              :disabled="addingNote"
              placeholder="Envoyer une mise à jour aux membres concernés par cet incident."
            />
            <icon-button
              class="input-group-append"
              :disabled="!noteText"
              role="send"
              :onclick="addNote"
            ></icon-button>
          </div>
          <template v-if="incident.show_details_to_blocked_borrowers" #description>
            <span class="small">
              <strong>Attention&nbsp;:</strong> cette mise à jour sera visible aux emprunteurs-ses
              affecté-es par cet incident. Assurez-vous de n'inclure aucune information sensible
              (adresse, numéro de permis, etc.)
            </span>
          </template>
        </b-form-group>
      </dd>
    </dl>

    <div class="mt-3 button-list justify-content-between">
      <template v-if="canUpdateIncident">
        <icon-button
          v-if="incident.status === 'in_process'"
          size="sm"
          variant="success"
          icon="check2-circle"
          :onclick="resolve"
          >Résoudre</icon-button
        >
        <icon-button
          v-if="incident.status === 'completed'"
          size="sm"
          variant="outline-danger"
          icon="x-octagon"
          :onclick="reopen"
          >Réouvrir</icon-button
        >
      </template>

      <dropdown-select
        :icon="incident.notification_subscription === 'none' ? 'bell-slash' : 'bell'"
        label="Notifications"
        size="sm"
        :value="incident.notification_subscription"
        :options="notificationOptions"
        :onselect="updateNotificationSubscription"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.incident-notes {
  max-height: 20rem;
  overflow: auto;
  margin-bottom: 1rem;
}

dt {
  margin-bottom: 0.5rem;
}
.row {
  container-type: inline-size;
  // Only split into two column when the row is larger than 800px
  @container (width > 800px) {
    .col-800-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
